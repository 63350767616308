// Variables override
$body-bg: #f5f8fa;
$theme-colors: (
    "primary": #C8CEE5,
    "success": #4caf50,
    "warning": #e7b332,
    "danger": #F5222D,
    "light": #d8d8d8,
);

:root {
    --gray-bg: rgb(245 248 250);
    --primary-lighter: #445491;
    --warning-lighter: #fff8c6;
    --gray-100: #f8f8f8;
    --gray-200: #dee1e2;
    --gray-300: #7D87AA;
    --gray-400: #e4e3e3;
    --gray-500: #a3a8ad;
    --gray-600: #888e94;
    --gray-700: #C8CEE5;
    --gray-900: #3a3838;
    --header-height: 50px;
}

// Boostrap scss
@import "~bootstrap/scss/bootstrap";

// React Data Table css
@import "./react-data-grid.css";

// Fonts
@import "fonts";

body {
    font-family: 'SF Pro Display Regular';
}

h1 {
    font-size: 1.8461538462rem;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 32px;
    margin-bottom: 2px;
    color: rgb(231, 235, 249);
}
h2 {
    font-size: 1.55rem;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 24px;
    color: rgb(231, 235, 249);
}
h4 {
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 16px;
    color: #7D87AA;
}
h5 {
    font-size: 1.2307692308rem;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.2307692308rem;
    margin-bottom: 16px;
    color: rgb(231, 235, 249);
}

h6 {
    color: rgb(231, 235, 249);
}

.small {
    color: var(--gray-500);
}

.big {
    font-size: 1.25rem;
}
// UI
.app-sections {
    min-height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.alert {
    color: var(--gray-100);
    display: flex;
    align-items: center;
    height: 56px;
    border: none;
    &.alert-success {
        background-color: theme-color("success");
    }
    &.alert-secondary {
        color: #E81B2E;
        background-color: #452233;
    }
    &.alert-warning {
        background-color: theme-color("warning");
    }
    &.alert-danger {
        background-color: theme-color("danger");
    }
}

.card-body {
    padding: 8px 22px 22px 22px;
}

.card-text {
    color: #7D87AA;
    font-size: 0.875rem;
    margin-top:8px;
}

.card-img, .card-img-top {
    padding: 12px;
    width: 95%;
}
.raw-btn {
    height: 40px;
}
.btn-white {
    color: theme-color("dark");
    background-color: white;
    border: none;
    border-bottom: 1px solid var(--light);
}
.raw-dropdown {
    height: 40px;
    .dropdown-toggle {
        text-align: left;
        height: 100%;
        &::after {
            right: 8px;
            bottom: calc(50% - 1px);
            position: absolute;
        }
    }
    .dropdown-menu {
        max-height: 40vh;
        overflow-y: auto;
        padding: 0;
        border: none;
        z-index: 1030;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
        border-bottom: 1px solid var(--light);
        .dropdown-header {
            padding: 0 8px;
            height: 24px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--light);
        }
        .dropdown-item {
            height: 40px;
            padding: 0 8px;
            display: flex;
            align-items: center;
            &:not(:last-child) {
                border-bottom: 1px solid var(--light);
            }
        }
    }
}
.raw-input-group {
    .raw-dropdown {
        button {
            padding-right: 3rem;
        }
    }
}
.raw-select {
    height: 40px;
    background-color: #2F324B;
    color: #565C80;
    line-height:24px;
    font-size:16px;
    border: none;
}
.raw-modal {
    .modal-content {
        background-color: var(--gray-bg);
        border: none;
        border-radius: 4px;
    }
    .modal-footer {
        border: none;
        justify-content: flex-start;
    }
}
.truncate-160px {
    max-width: 160px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.card {
    border-color: #22273E;
    &:hover {

    }
    &.active {
        border-color: #2F54EB;
    }
}
.cursorpointer,
.cursor-pointer {
    cursor: pointer;
}
.nowrap,
.no-wrap {
    white-space: nowrap;
}
.text-field {
    background-color: #2F324B;
    color: #565C80;
    line-height:24px;
    font-size:16px;
    height: 40px;
    border: none;
    border-radius: 4px;
    &::placeholder {
        color: #565C80;
    }
}
.underlined {
    border-bottom: 1px solid theme-color("primary");
}
.divider {
    width: 100%;
    border-top: 1px solid var(--gray-400);
    display: inline-block;
}

a {
    text-decoration: none !important;
}

a:hover {
    text-decoration: none !important;
}

.apexcharts-tooltip {
    box-shadow: none !important;
    background-color: rgba(34, 39, 62, 1);
}