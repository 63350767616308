@font-face {
    font-family: "SFProDisplay";
    src: url("SFPRODISPLAYREGULAR.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("SFPRODISPLAYREGULAR.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("SFPRODISPLAYREGULAR.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("SFPRODISPLAYMEDIUM.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("SFPRODISPLAYBOLD.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display Regular";
    font-style: normal;
    font-weight: normal;
    src: local("SF Pro Display Regular"),
        url("SFPRODISPLAYREGULAR.woff") format("woff");
}
