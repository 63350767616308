/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #22273E;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1D2033;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 8px;
  background: #1D2033;
}